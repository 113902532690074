@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans-VariableFont_wdth,wght.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Faktum";
  src: url("./fonts/Faktum-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Faktum";
  src: url("./fonts/Faktum-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Faktum";
  src: url("./fonts/Faktum-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Faktum";
  src: url("./fonts//Faktum-Bold.woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Faktum";
  src: url("./fonts/Faktum-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

html {
  scroll-behavior: smooth;
}
